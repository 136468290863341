
import { defineComponent, ref, onMounted } from 'vue'
import SecondFooter from '@/components/SecondFooter.vue'
import AIGCModel from '@/components/AIGCModel.vue'

export default defineComponent({
  name: 'AIGC',
  components: {
    SecondFooter,
    AIGCModel
  },
  setup(){
    const aigcmodelVis = ref<boolean>(false);
    const changeAigcStatus = (bool: boolean): void => {
      aigcmodelVis.value = bool
      if(bool){
        document.body.style.overflowY = 'hidden'
      }else{
        document.body.style.overflowY = 'scroll'
      }
    }
    onMounted(() => {
      _hmt.push(['_trackPageview', '/aigc'])
    })
    return {
      aigcmodelVis,
      changeAigcStatus
    }
  }
})
