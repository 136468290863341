
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'AIGCModel',
  props: {
    changeAigcStatus: {
      required: true,
      type: Function
    }
  },
  setup(){
  }
})
